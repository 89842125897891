<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <h1>asdf</h1>
    <!-- <b-link
      class="brand-logo"
      href="/"
    >
      <b-img-lazy
        fluid
        :src="sideImg"
        alt="logo SEOcloud"
        class="img-logo"
        loading="lazy"
      />
    </b-link> -->

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1" style="color: #fff !important;">
          Payment Success 🎉
        </h2>
        <p class="mb-2">
          Thank you! 💗 Explore and enjoy your plan.
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          style="border: 0px;"
          :to="{path:'/'}"
        >
          Explore new tools
        </b-button>

        <!-- image -->
        <b-img-lazy
          fluid
          :src="imgUrl"
          alt="Error page"
          loading="lazy"
        />
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BButton, BImg, BImgLazy,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import confetti from 'canvas-confetti';

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
    BImgLazy,
  },
  data() {
    return {
      sideImg: require('@/assets/images/logo/logo-seocloud.svg'),
      downImg: require('@/assets/images/pages/coming-soon.svg'),
    }
  },
  methods: {
    celebrate() {
      confetti({
        particleCount: 150,
        spread: 170,
        origin: { y: 0.6 },
      });
    },
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  created() {
    this.celebrate()
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>

<style>
.img-logo {
  width: 340px;
  height: 40px;
  position: relative;
  top: 25px;
  left: 20px;
}
</style>
